import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function ImageDownloaderRateUs(props) {
    const headers = {
        "ar": ["تم تحديث امتداد تنزيل الصور الخاص بك إلى أحدث إصدار"],
        "am": ["የእርስዎ ኤክስቴንሽን የምስል አውርዶ ወደ ቅንብሩ ተሻሽሏል"],
        "bg": ["Вашето разширение за изтегляне на изображения беше актуализирано до най-новата версия"],
        "bn": ["আপনার এক্সটেনশন ইমেজ ডাউনলোডারটি সর্বশেষ সংস্করণে আপডেট করা হয়েছে"],
        "ca": ["La teva extensió de descarregador d'imatges s'ha actualitzat a l'última versió"],
        "cs": ["Vaše rozšíření pro stahování obrázků bylo aktualizováno na nejnovější verzi"],
        "da": ["Din udvidelse billeddownloader blev opdateret til den nyeste version"],
        "de": ["Ihre Erweiterung Bild-Downloader wurde auf die neueste Version aktualisiert"],
        "el": ["Η επέκταση λήψης εικόνας ενημερώθηκε στην πιο πρόσφατη έκδοση"],
        "en": ["Your extension Image downloader was updated to the latest version"],
        "es": ["Su extensión de descargador de imágenes se actualizó a la última versión"],
        "et": ["Teie pildiallalaadija laiend on värskendatud uusimale versioonile"],
        "fa": ["افزونه دانلود کننده تصویر شما به آخرین نسخه به‌روزرسانی شد"],
        "fi": ["Laajennuksesi kuvien latausohjelma päivitettiin uusimpaan versioon"],
        "fil": ["Ang iyong extension Image downloader ay na-update sa pinakabagong bersyon"],
        "fr": ["Votre extension de téléchargeur d'images a été mise à jour vers la dernière version"],
        "gu": ["તમારું એક્સટેન્શન છબી ડાઉનલોડર નવીનતમ સંસ્કરણમાં અપડેટ થયું"],
        "he": ["ההרחבה שלך להורדת תמונות עודכנה לגרסה האחרונה"],
        "hi": ["आपके एक्सटेंशन इमेज डाउनलोडर को नवीनतम संस्करण में अपडेट किया गया"],
        "hr": ["Vaše proširenje za preuzimanje slika ažurirano je na najnoviju verziju"],
        "hu": ["A képletöltő bővítménye frissült a legújabb verzióra"],
        "id": ["Ekstensi pengunduh gambar Anda diperbarui ke versi terbaru"],
        "it": ["La tua estensione per il download di immagini è stata aggiornata all'ultima versione"],
        "ja": ["拡張機能の画像ダウンローダーが最新バージョンに更新されました"],
        "kn": ["ನಿಮ್ಮ ವಿಸ್ತರಣೆ ಚಿತ್ರ ಡೌನ್‌ಲೋಡರ್ ಅನ್ನು ಇತ್ತೀಚಿನ ಆವೃತ್ತಿಗೆ ನವೀಕರಿಸಲಾಗಿದೆ"],
        "ko": ["귀하의 확장 프로그램 이미지 다운로더가 최신 버전으로 업데이트되었습니다"],
        "lt": ["Jūsų plėtinys vaizdų atsisiuntimo programa atnaujinta į naujausią versiją"],
        "lv": ["Jūsu paplašinājums attēlu lejupielādētājs tika atjaunināts uz jaunāko versiju"],
        "ml": ["നിങ്ങളുടെ എക്സ്റ്റെൻഷൻ ഇമേജ് ഡൗൺലോഡർ ഏറ്റവും പുതിയ പതിപ്പിലേക്ക് അപ്‌ഡേറ്റ് ചെയ്യപ്പെട്ടു"],
        "mr": ["तुमचा विस्तार इमेज डाउनलोडर नवीनतम आवृत्तीत अद्यतनित केला गेला आहे"],
        "ms": ["Sambungan pemuat turun imej anda telah dikemas kini ke versi terkini"],
        "nl": ["Uw extensie afbeeldingsdownloader is bijgewerkt naar de nieuwste versie"],
        "no": ["Utvidelsen din for bildenedlasting ble oppdatert til den nyeste versjonen"],
        "pl": ["Twoje rozszerzenie do pobierania obrazów zostało zaktualizowane do najnowszej wersji"],
        "pt": ["Sua extensão de downloader de imagens foi atualizada para a versão mais recente"],
        "ro": ["Extensia dvs. de descărcare a imaginilor a fost actualizată la cea mai recentă versiune"],
        "ru": ["Ваше расширение для загрузки изображений было обновлено до последней версии"],
        "sk": ["Vaša rozšírenie na sťahovanie obrázkov bolo aktualizované na najnovšiu verziu"],
        "sl": ["Vaša razširitev za prenos slik je bila posodobljena na najnovejšo različico"],
        "sr": ["Ваше проширење за преузимање слика је ажурирано на најновију верзију"],
        "sv": ["Din tilläggsprogram för bildnedladdning har uppdaterats till den senaste versionen"],
        "sw": ["Kiendelezi chako cha kupakua picha kimesasishwa hadi toleo jipya zaidi"],
        "ta": ["உங்கள் நீட்டிப்பு படத்தைப் பதிவிறக்கி சமீபத்திய பதிப்பிற்கு புதுப்பிக்கப்பட்டது"],
        "te": ["మీ విస్తరణ చిత్రం డౌన్‌లోడర్‌ను తాజా వెర్షన్‌కు అప్డేట్ చేయబడింది"],
        "th": ["โปรแกรมเสริมดาวน์โหลดรูปภาพของคุณได้รับการอัปเดตเป็นเวอร์ชันล่าสุด"],
        "tr": ["Uzantınızın görüntü indiricisi en son sürüme güncellendi"],
        "uk": ["Ваше розширення для завантаження зображень було оновлено до останньої версії"],
        "vi": ["Tiện ích mở rộng tải xuống hình ảnh của bạn đã được cập nhật lên phiên bản mới nhất"],
        "zh_CN": ["您的扩展程序图像下载器已更新到最新版本"],
        "zh_TW": ["您的擴充功能圖像下載器已更新到最新版本"],
        "zu": ["Isandiso sakho sokulanda izithombe sibuyekeziwe kuya kunguqulo lwakamuva"]
    };

    const text = {
        "en": {
            "p1": "We work hard to make it the best image download extension. If you are happy with our extension",
            "p2": "please consider",
            "p3": "rating the extension.",
            "p4": "what's new",
            "p5": "We improve the image capture mechanism - we now have fewer misses.",
            "p6": "We fixed some bugs.",
            "p7": "We made improvements to the user interface."
        },
        "ar": {
            "p1": "نعمل بجد لجعلها أفضل إضافة لتنزيل الصور. إذا كنت سعيدًا بإضافتنا",
            "p2": "يرجى النظر في",
            "p3": "تقييم الإضافة.",
            "p4": "ما الجديد",
            "p5": "لقد قمنا بتحسين آلية التقاط الصور - الآن لدينا فرص أقل للفشل.",
            "p6": "لقد أصلحنا بعض الأخطاء.",
            "p7": "قمنا بتحسين واجهة المستخدم."
        },
        "am": {
            "p1": "እኛ እንቀላቀል የምስል አውርዶ ቅንብሩን እንዲሆን እንድለምን፣ እንደ እርስዎ እንደ ተጠቃሚ",
            "p2": "እባክዎን ያስቡ",
            "p3": "ለእባክዎን እንዲቆይ እንበራክራት.",
            "p4": "አዲስ ምንድን ነው",
            "p5": "እኛ እንቀላቀል የምስል አውርዶ ቅንብሩን እንዲሆን እንድለምን፣ እንደ እርስዎ እንደ ተጠቃሚ",
            "p6": "ምንኛውም ጉዳተኛውን አበርታችኋል.",
            "p7": "እኛንናል በማይኛውም"
        },
        "bg": {
            "p1": "Работим усилено, за да го направим най-доброто разширение за изтегляне на изображения. Ако сте доволни от нашето разширение",
            "p2": "моля, обмислете",
            "p3": "да оцените разширението.",
            "p4": "какво ново",
            "p5": "Подобрихме механизма за улавяне на изображения - сега имаме по-малко пропуски.",
            "p6": "Поправихме някои грешки.",
            "p7": "Подобрихме потребителския интерфейс."
        },
        "bn": {
            "p1": "আমরা এটিকে সেরা চিত্র ডাউনলোড এক্সটেনশন করতে কঠোর পরিশ্রম করি। আপনি যদি আমাদের এক্সটেনশন নিয়ে খুশি হন",
            "p2": "অনুগ্রহ করে বিবেচনা করুন",
            "p3": "এক্সটেনশনটি রেটিং করুন।",
            "p4": "কি নতুন",
            "p5": "আমরা চিত্র ক্যাপচার মেকানিজম উন্নত করেছি - এখন আমাদের মিসেস কম।",
            "p6": "আমরা কিছু বাগ ঠিক করেছি।",
            "p7": "আমরা ব্যবহারকারী ইন্টারফেসে উন্নতি করেছি।"
        },
        "ca": {
            "p1": "Treballem dur per fer que sigui la millor extensió de descàrrega d'imatges. Si estàs content amb la nostra extensió",
            "p2": "si us plau considera",
            "p3": "valorar l'extensió.",
            "p4": "què hi ha de nou",
            "p5": "Hem millorat el mecanisme de captura d'imatges - ara tenim menys errors.",
            "p6": "Hem corregit alguns errors.",
            "p7": "Hem millorat la interfície d'usuari."
        },
        "cs": {
            "p1": "Tvrdě pracujeme na tom, aby to bylo nejlepší rozšíření pro stahování obrázků. Pokud jste s naším rozšířením spokojeni",
            "p2": "prosím zvažte",
            "p3": "hodnocení rozšíření.",
            "p4": "co je nového",
            "p5": "Vylepšili jsme mechanismus pro snímání obrázků – nyní máme méně chyb.",
            "p6": "Opravili jsme několik chyb.",
            "p7": "Vylepšili jsme uživatelské rozhraní."
        },
        "da": {
            "p1": "Vi arbejder hårdt for at gøre det til den bedste billeddownload-udvidelse. Hvis du er tilfreds med vores udvidelse",
            "p2": "overvej venligst",
            "p3": "at bedømme udvidelsen.",
            "p4": "hvad er nyt",
            "p5": "Vi har forbedret billedfangstmekanismen – vi har nu færre fejl.",
            "p6": "Vi har rettet nogle fejl.",
            "p7": "Vi har forbedret brugergrænsefladen."
        },
        "de": {
            "p1": "Wir arbeiten hart daran, die beste Bild-Downloader-Erweiterung zu machen. Wenn Sie mit unserer Erweiterung zufrieden sind",
            "p2": "bitte erwägen",
            "p3": "die Erweiterung zu bewerten.",
            "p4": "was ist neu",
            "p5": "Wir haben den Bildaufnahmemechanismus verbessert – jetzt gibt es weniger Fehlversuche.",
            "p6": "Wir haben einige Fehler behoben.",
            "p7": "Wir haben die Benutzeroberfläche verbessert."
        },
        "el": {
            "p1": "Δουλεύουμε σκληρά για να το κάνουμε την καλύτερη επέκταση λήψης εικόνας. Εάν είστε ευχαριστημένοι με την επέκτασή μας",
            "p2": "παρακαλώ σκεφτείτε",
            "p3": "να αξιολογήσετε την επέκταση.",
            "p4": "τι νέο υπάρχει",
            "p5": "Βελτιώσαμε τον μηχανισμό λήψης εικόνας - τώρα έχουμε λιγότερα λάθη.",
            "p6": "Διορθώσαμε μερικά σφάλματα.",
            "p7": "Βελτιώσαμε το περιβάλλον χρήστη."
        },
        "es": {
            "p1": "Trabajamos duro para que sea la mejor extensión de descarga de imágenes. Si estás contento con nuestra extensión",
            "p2": "por favor considera",
            "p3": "calificar la extensión.",
            "p4": "qué hay de nuevo",
            "p5": "Hemos mejorado el mecanismo de captura de imágenes: ahora tenemos menos fallos.",
            "p6": "Hemos corregido algunos errores.",
            "p7": "Hemos mejorado la interfaz de usuario."
        },
        "et": {
            "p1": "Töötame kõvasti selle nimel, et see oleks parim pildilaiend. Kui olete meie laiendiga rahul",
            "p2": "palun kaaluge",
            "p3": "laienduse hindamist.",
            "p4": "mis on uut",
            "p5": "Parandasime piltide jäädvustamise mehhanismi – nüüd on meil vähem ebaõnnestumisi.",
            "p6": "Parandasime mõned vead.",
            "p7": "Parandasime kasutajaliidest."
        },
        "fa": {
            "p1": "ما سخت کار می‌کنیم تا بهترین افزونه دانلود تصویر را بسازیم. اگر از افزونه ما راضی هستید",
            "p2": "لطفا در نظر بگیرید",
            "p3": "رتبه‌دهی به افزونه.",
            "p4": "چه چیزی جدید است",
            "p5": "ما مکانیزم ضبط تصویر را بهبود بخشیده‌ایم - اکنون خطاهای کمتری داریم.",
            "p6": "ما برخی از باگ‌ها را برطرف کردیم.",
            "p7": "ما رابط کاربری را بهبود بخشیده‌ایم."
        },
        "fi": {
            "p1": "Teemme kovasti töitä tehdäksemme tästä parhaan kuvanlatauslaajennuksen. Jos olet tyytyväinen laajennukseemme",
            "p2": "harkitse",
            "p3": "laajennuksen arviointia.",
            "p4": "mitä uutta",
            "p5": "Olemme parantaneet kuvanottomekanismia – nyt on vähemmän virheitä.",
            "p6": "Korjasimme joitakin virheitä.",
            "p7": "Paransimme käyttöliittymää."
        },
        "fr": {
            "p1": "Nous travaillons dur pour en faire la meilleure extension de téléchargement d'images. Si vous êtes satisfait de notre extension",
            "p2": "veuillez envisager",
            "p3": "d'évaluer l'extension.",
            "p4": "quoi de neuf",
            "p5": "Nous avons amélioré le mécanisme de capture d'image - nous avons maintenant moins d'erreurs.",
            "p6": "Nous avons corrigé certains bogues.",
            "p7": "Nous avons amélioré l'interface utilisateur."
        },
        "he": {
            "p1": "אנחנו עובדים קשה כדי להפוך את זה לתוסף ההורדות הטוב ביותר של תמונות. אם אתם מרוצים מהתוסף שלנו",
            "p2": "שקלו בבקשה",
            "p3": "לדרג את התוסף.",
            "p4": "מה חדש",
            "p5": "שיפרנו את מנגנון לכידת התמונות – עכשיו יש פחות טעויות.",
            "p6": "תיקנו כמה באגים.",
            "p7": "שיפרנו את ממשק המשתמש."
        },
        "hi": {
            "p1": "हम इसे सर्वश्रेष्ठ छवि डाउनलोड एक्सटेंशन बनाने के लिए कड़ी मेहनत करते हैं। यदि आप हमारे एक्सटेंशन से खुश हैं",
            "p2": "कृपया विचार करें",
            "p3": "एक्सटेंशन को रेटिंग दें।",
            "p4": "नया क्या है",
            "p5": "हमने छवि कैप्चर तंत्र में सुधार किया है - अब हमारी कम गलतियाँ होती हैं।",
            "p6": "हमने कुछ बग्स को ठीक किया है।",
            "p7": "हमने उपयोगकर्ता इंटरफ़ेस में सुधार किया है।"
        },
        "hu": {
            "p1": "Keményen dolgozunk azon, hogy a legjobb képletöltő kiterjesztés legyen. Ha elégedett a bővítményünkkel",
            "p2": "kérjük, fontolja meg",
            "p3": "a kiterjesztés értékelését.",
            "p4": "mi újság",
            "p5": "Fejlesztettük a képrögzítési mechanizmust – most kevesebb hiba van.",
            "p6": "Javítottunk néhány hibát.",
            "p7": "Fejlesztettük a felhasználói felületet."
        },
        "id": {
            "p1": "Kami bekerja keras untuk menjadikannya ekstensi unduhan gambar terbaik. Jika Anda senang dengan ekstensi kami",
            "p2": "mohon pertimbangkan",
            "p3": "untuk memberi penilaian pada ekstensi ini.",
            "p4": "apa yang baru",
            "p5": "Kami meningkatkan mekanisme pengambilan gambar - sekarang kami memiliki lebih sedikit kesalahan.",
            "p6": "Kami memperbaiki beberapa bug.",
            "p7": "Kami meningkatkan antarmuka pengguna."
        },
        "it": {
            "p1": "Lavoriamo sodo per renderlo il miglior componente aggiuntivo per il download di immagini. Se sei soddisfatto del nostro componente aggiuntivo",
            "p2": "prendi in considerazione",
            "p3": "valutare il componente aggiuntivo.",
            "p4": "novità",
            "p5": "Abbiamo migliorato il meccanismo di acquisizione delle immagini - ora abbiamo meno errori.",
            "p6": "Abbiamo risolto alcuni bug.",
            "p7": "Abbiamo migliorato l'interfaccia utente."
        },
        "ja": {
            "p1": "最高の画像ダウンロード拡張機能を目指して努力しています。 拡張機能に満足している場合",
            "p2": "ぜひご検討ください",
            "p3": "拡張機能の評価をお願いします。",
            "p4": "新機能",
            "p5": "画像キャプチャのメカニズムを改善しました - ミスが減りました。",
            "p6": "いくつかのバグを修正しました。",
            "p7": "ユーザーインターフェイスを改善しました。"
        },
        "ko": {
            "p1": "최고의 이미지 다운로드 확장 기능을 만들기 위해 열심히 노력하고 있습니다. 확장 기능에 만족하셨다면",
            "p2": "고려해 주세요",
            "p3": "확장 기능을 평가하십시오.",
            "p4": "새로운 기능",
            "p5": "이미지 캡처 메커니즘을 개선했습니다. 이제 놓치는 경우가 적습니다.",
            "p6": "몇 가지 버그를 수정했습니다.",
            "p7": "사용자 인터페이스를 개선했습니다."
        },
        "lt": {
            "p1": "Mes sunkiai dirbame, kad tai būtų geriausias vaizdų atsisiuntimo plėtinys. Jei esate patenkintas mūsų plėtiniu",
            "p2": "prašome apsvarstyti",
            "p3": "plėtinio įvertinimą.",
            "p4": "kas naujo",
            "p5": "Patobulinome vaizdų fiksavimo mechanizmą – dabar yra mažiau klaidų.",
            "p6": "Ištaisėme keletą klaidų.",
            "p7": "Patobulinome vartotojo sąsają."
        },
        "lv": {
            "p1": "Mēs smagi strādājam, lai padarītu to par labāko attēlu lejupielādes paplašinājumu. Ja esat apmierināts ar mūsu paplašinājumu",
            "p2": "lūdzu, apsveriet",
            "p3": "paplašinājuma vērtēšanu.",
            "p4": "kas jauns",
            "p5": "Mēs uzlabojām attēlu uztveršanas mehānismu - tagad mums ir mazāk kļūdu.",
            "p6": "Mēs labojām dažas kļūdas.",
            "p7": "Mēs uzlabojām lietotāja saskarni."
        },
        "ms": {
            "p1": "Kami bekerja keras untuk menjadikannya sambungan muat turun imej yang terbaik. Jika anda gembira dengan sambungan kami",
            "p2": "sila pertimbangkan untuk",
            "p3": "menilai sambungan ini.",
            "p4": "apa yang baharu",
            "p5": "Kami telah memperbaiki mekanisme tangkapan imej - sekarang terdapat lebih sedikit kegagalan.",
            "p6": "Kami telah membetulkan beberapa pepijat.",
            "p7": "Kami telah menambah baik antara muka pengguna."
        },
        "nl": {
            "p1": "We werken hard om het de beste afbeeldingsdownload-extensie te maken. Als je tevreden bent met onze extensie",
            "p2": "overweeg dan",
            "p3": "de extensie te beoordelen.",
            "p4": "wat is er nieuw",
            "p5": "We hebben het mechanisme voor het vastleggen van afbeeldingen verbeterd – we hebben nu minder fouten.",
            "p6": "We hebben enkele bugs verholpen.",
            "p7": "We hebben de gebruikersinterface verbeterd."
        },
        "pl": {
            "p1": "Ciężko pracujemy, aby uczynić to najlepszym rozszerzeniem do pobierania obrazów. Jeśli jesteś zadowolony z naszego rozszerzenia",
            "p2": "rozważ proszę",
            "p3": "ocenienie rozszerzenia.",
            "p4": "co nowego",
            "p5": "Ulepszyliśmy mechanizm przechwytywania obrazu - teraz mamy mniej błędów.",
            "p6": "Naprawiliśmy kilka błędów.",
            "p7": "Ulepszyliśmy interfejs użytkownika."
        },
        "pt": {
            "p1": "Trabalhamos arduamente para torná-lo a melhor extensão de download de imagens. Se você está satisfeito com nossa extensão",
            "p2": "por favor considere",
            "p3": "avaliar a extensão.",
            "p4": "o que há de novo",
            "p5": "Melhoramos o mecanismo de captura de imagens - agora temos menos falhas.",
            "p6": "Corrigimos alguns erros.",
            "p7": "Melhoramos a interface do usuário."
        },
        "ro": {
            "p1": "Lucrăm din greu pentru a face cea mai bună extensie de descărcare a imaginilor. Dacă sunteți mulțumit de extensia noastră",
            "p2": "vă rugăm să luați în considerare",
            "p3": "evaluarea extensiei.",
            "p4": "ce este nou",
            "p5": "Am îmbunătățit mecanismul de captare a imaginilor - acum avem mai puține erori.",
            "p6": "Am reparat câteva erori.",
            "p7": "Am îmbunătățit interfața utilizatorului."
        },
        "ru": {
            "p1": "Мы усердно работаем, чтобы сделать это лучшим расширением для загрузки изображений. Если вы довольны нашим расширением",
            "p2": "пожалуйста, рассмотрите",
            "p3": "оценку расширения.",
            "p4": "что нового",
            "p5": "Мы улучшили механизм захвата изображений - теперь у нас меньше ошибок.",
            "p6": "Мы исправили некоторые ошибки.",
            "p7": "Мы улучшили интерфейс пользователя."
        },
        "sr": {
            "p1": "Naporno radimo da ovo bude najbolji dodatak za preuzimanje slika. Ako ste zadovoljni našim dodatkom",
            "p2": "molimo vas da razmislite o",
            "p3": "ocenjivanju dodatka.",
            "p4": "šta je novo",
            "p5": "Poboljšali smo mehanizam za snimanje slike – sada imamo manje promašaja.",
            "p6": "Ispravili smo nekoliko grešaka.",
            "p7": "Poboljšali smo korisnički interfejs."
        },
        "sv": {
            "p1": "Vi arbetar hårt för att göra det till den bästa bildnedladdningstillägget. Om du är nöjd med vårt tillägg",
            "p2": "överväg gärna att",
            "p3": "betygsätta tillägget.",
            "p4": "vad är nytt",
            "p5": "Vi har förbättrat mekanismen för bildfångst – nu har vi färre fel.",
            "p6": "Vi har åtgärdat några buggar.",
            "p7": "Vi har förbättrat användargränssnittet."
        },
        "th": {
            "p1": "เราทำงานอย่างหนักเพื่อทำให้มันเป็นส่วนขยายดาวน์โหลดภาพที่ดีที่สุด หากคุณพอใจกับส่วนขยายของเรา",
            "p2": "โปรดพิจารณา",
            "p3": "ให้คะแนนส่วนขยายนี้",
            "p4": "มีอะไรใหม่",
            "p5": "เราได้ปรับปรุงกลไกการจับภาพ - ตอนนี้เรามีความผิดพลาดน้อยลง",
            "p6": "เราได้แก้ไขข้อบกพร่องบางประการ",
            "p7": "เราได้ปรับปรุงส่วนต่อประสานผู้ใช้"
        },
        "tr": {
            "p1": "Bunu en iyi resim indirme uzantısı yapmak için çok çalışıyoruz. Uzantımızdan memnun kaldıysanız",
            "p2": "lütfen düşünün",
            "p3": "uzantıyı değerlendirin.",
            "p4": "yenilikler",
            "p5": "Görüntü yakalama mekanizmasını geliştirdik – artık daha az hata var.",
            "p6": "Bazı hataları düzelttik.",
            "p7": "Kullanıcı arayüzünü geliştirdik."
        },
        "uk": {
            "p1": "Ми наполегливо працюємо, щоб зробити це найкращим розширенням для завантаження зображень. Якщо ви задоволені нашим розширенням",
            "p2": "будь ласка, подумайте про",
            "p3": "оцінку розширення.",
            "p4": "що нового",
            "p5": "Ми вдосконалили механізм захоплення зображення - тепер у нас менше помилок.",
            "p6": "Ми виправили деякі помилки.",
            "p7": "Ми покращили інтерфейс користувача."
        },
        "vi": {
            "p1": "Chúng tôi làm việc chăm chỉ để làm cho nó trở thành tiện ích mở rộng tải xuống hình ảnh tốt nhất. Nếu bạn hài lòng với tiện ích mở rộng của chúng tôi",
            "p2": "vui lòng xem xét",
            "p3": "đánh giá tiện ích mở rộng.",
            "p4": "có gì mới",
            "p5": "Chúng tôi đã cải thiện cơ chế chụp ảnh - giờ đây chúng tôi ít bị bỏ lỡ hơn.",
            "p6": "Chúng tôi đã sửa một số lỗi.",
            "p7": "Chúng tôi đã cải thiện giao diện người dùng."
        },
        "zh_CN": {
            "p1": "我们努力工作以使其成为最好的图像下载扩展程序。如果您对我们的扩展程序感到满意",
            "p2": "请考虑",
            "p3": "对扩展程序进行评分。",
            "p4": "更新内容",
            "p5": "我们改进了图像捕获机制 - 现在我们少有错误。",
            "p6": "我们修复了一些漏洞。",
            "p7": "我们改进了用户界面。"
        },
        "zh_TW": {
            "p1": "我們努力工作使其成為最佳的圖像下載擴充功能。如果您對我們的擴充功能感到滿意",
            "p2": "請考慮",
            "p3": "評價此擴充功能。",
            "p4": "更新內容",
            "p5": "我們改進了圖像擷取機制 - 現在錯誤更少。",
            "p6": "我們修復了一些漏洞。",
            "p7": "我們改進了使用者介面。"
        }
    };




const { browser, lang } = useParams();
let url = "https://chromewebstore.google.com/detail/image-downloader-save-all/fpelahbljekknahkcaegamhcndkfpfnc";
if (browser == "edge") {
    url = "https://microsoftedge.microsoft.com/addons/detail/image-downloader-save-a/flbolonkboilcbnohjlchlfgdalomdpl";
} else if (browser == "firefox") {
    url = "https://addons.mozilla.org/en-US/firefox/addon/image-downloader-for-firefox/";
} else if (browser == "opera") {
    url = "opera"
}
let header = headers[lang];
let p1 = text[lang]['p1'];
let p2 = text[lang]['p2'];
let p3 = text[lang]['p3'];
let p4 = text[lang]['p4'];
let p5 = text[lang]['p5'];
let p6 = text[lang]['p6'];
let p7 = text[lang]['p7'];
let enLink = "../image_downloader-rate_us/" + browser + "/en";
return (
    <>

        <div style={{ backgroundImage: "URL(/../assets/meercat-download-images.jpeg)", width: "1024px", height: "1024px" }}>
            <div style={{ color: "white", margin: "48px" }}>
                <br />
                <h2>
                    <div style={{ backgroundColor: "rgb(0,0,0,0.6)", paddingLeft: "8px", paddingBottom: "8px" }}><p>
                    <div>
            <NavLink to="/en/contact"><img src="/../assets/email-icon.svg" style={{ float: "right", width: "48px", margin: "16px" }} /></NavLink>
            {lang == 'en' ? "" : <NavLink to={enLink}><img src="/../assets/en.svg" style={{ float: "right", width: "44px", margin: "24px 12px" }} /></NavLink>}
            <h2 style={{ color: "white", padding: "4px" }}>{header}</h2>
        </div>
                        <h2>{p4}</h2>
                        <ul>
                            <li>
                                {p5}
                            </li><br />
                        </ul></p>{p1} {p2} <a href={url} style={{ padding: "4px", color: "orange" }}>{p3}</a></div>

                </h2>
            </div>
        </div>
    </>
)
}