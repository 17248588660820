import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function NoSwipeRateUs(props){

    const appNameTrans = {
        "ar": {
            "appName": {"message": "No Swipe - تعطيل السحب"},
            "appDesc": {"message": "تعطيل التنقل بالإصبعين على لوحة اللمس."}
        },
        "am": {
            "appName": {"message": "No Swipe - ትክክለኛ ያግኝ"},
            "appDesc": {"message": "የአካባቢ እርምጃ ያደርጋል ሁለት አንቀላፍ የካርታ በመጠቀም።"}
        },
        "bg": {
            "appName": {"message": "No Swipe - Деактивиране на плъзгането"},
            "appDesc": {"message": "Деактивиране на навигацията с два пръста върху тъчпада."}
        },
        "bn": {
            "appName": {"message": "No Swipe - স্যুইপ অক্ষম করুন"},
            "appDesc": {"message": "টাচপ্যাডের দুটি আঙুল নেভিগেশন নিষ্ক্রিয় করুন।"}
        },
        "ca": {
            "appName": {"message": "No Swipe - Desactivar el lliscament"},
            "appDesc": {"message": "Desactiva la navegació amb dos dits al touchpad."}
        },
        "cs": {
            "appName": {"message": "No Swipe - Zakázat posouvání"},
            "appDesc": {"message": "Zakázat navigaci dvěma prsty na touchpadu."}
        },
        "da": {
            "appName": {"message": "No Swipe - Deaktiver swipe"},
            "appDesc": {"message": "Deaktiver tofingernavigation på touchpad."}
        },
        "de": {
            "appName": {"message": "No Swipe - Wischen deaktivieren"},
            "appDesc": {"message": "Deaktiviere die Touchpad-Zwei-Finger-Navigation."}
        },
        "el": {
            "appName": {"message": "No Swipe - Απενεργοποίηση σύρσιμου"},
            "appDesc": {"message": "Απενεργοποίηση πλοήγησης με δύο δάχτυλα στο touchpad."}
        },
        "en": {
            "appName": {"message": "No Swipe - Disable Swipe"},
            "appDesc": {"message": "Disable trackpad two fingers navigation."}
        },
        "en_AU": {
            "appName": {"message": "No Swipe - Disable Swipe"},
            "appDesc": {"message": "Disable trackpad two fingers navigation."}
        },
        "en_GB": {
            "appName": {"message": "No Swipe - Disable Swipe"},
            "appDesc": {"message": "Disable trackpad two fingers navigation."}
        },
        "en_US": {
            "appName": {"message": "No Swipe - Disable Swipe"},
            "appDesc": {"message": "Disable trackpad two fingers navigation."}
        },
        "es": {
            "appName": {"message": "No Swipe - Desactivar deslizamiento"},
            "appDesc": {"message": "Desactiva la navegación con dos dedos en el panel táctil."}
        },
        "es_419": {
            "appName": {"message": "No Swipe - Desactivar deslizamiento"},
            "appDesc": {"message": "Desactiva la navegación con dos dedos en el panel táctil."}
        },
        "et": {
            "appName": {"message": "No Swipe - Keela libistamine"},
            "appDesc": {"message": "Keela puuteplaatide navigeerimine kahe sõrmega."}
        },
        "fa": {
            "appName": {"message": "No Swipe - غیرفعال کردن کشیدن"},
            "appDesc": {"message": "غیرفعال کردن دو انگشت ناوبری در تاچ‌پد."}
        },
        "fi": {
            "appName": {"message": "No Swipe - Poista pyyhkäisy"},
            "appDesc": {"message": "Poista kosketuslevyn kahden sormen navigointi."}
        },
        "fil": {
            "appName": {"message": "No Swipe - Huwag Galawin ang Swipe"},
            "appDesc": {"message": "I-disable ang daliring navigasyon sa touchpad."}
        },
        "fr": {
            "appName": {"message": "No Swipe - Désactiver le balayage"},
            "appDesc": {"message": "Désactivez la navigation à deux doigts sur le pavé tactile."}
        },
        "gu": {
            "appName": {"message": "No Swipe - સ્વાઈપ અક્ષમ કરો"},
            "appDesc": {"message": "ટચપેડ દોડા નેવિગેશન નિષ્ક્રિય કરો બીજી ટેકલીનો સાંધાન."}
        },
        "he": {
            "appName": {"message": "No Swipe - השבת החלקה"},
            "appDesc": {"message": "מבטל ניווט בעזרת שתי אצבעות על משטח מגע."}
        },
        "hi": {
            "appName": {"message": "No Swipe - स्वाइप को अक्षम करें"},
            "appDesc": {"message": "टचपैड पर दो उंगलियों की नेविगेशन को निष्क्रिय करें।"}
        },
        "hr": {
            "appName": {"message": "No Swipe - Onemogući povlačenje"},
            "appDesc": {"message": "Onemogući navigaciju dva prsta na touchpadu."}
        },
        "hu": {
            "appName": {"message": "No Swipe - Pörges tiltása"},
            "appDesc": {"message": "A touchpad kéttakarós navigációjának letiltása."}
        },
        "id": {
            "appName": {"message": "No Swipe - Menonaktifkan Swipe"},
            "appDesc": {"message": "Menonaktifkan navigasi dua jari di touchpad."}
        },
        "it": {
            "appName": {"message": "No Swipe - Disabilita lo Swipe"},
            "appDesc": {"message": "Disabilita la navigazione a due dita sul touchpad."}
        },
        "ja": {
            "appName": {"message": "No Swipe - スワイプを無効にする"},
            "appDesc": {"message": "タッチパッドの2本指ナビゲーションを無効にします。"}
        },
        "kn": {
            "appName": {"message": "No Swipe - ಸ್ವೈಪ್ ನಿಲ್ಲಿಸಿ"},
            "appDesc": {"message": "ಟಚ್‌ಪ್ಯಾಡ್‌ನಲ್ಲಿ ಎರಡು ಬೆರಳು ನ್ಯಾವಿಗೇಷನ್‌ನನ್ನು ನಿಲ್ಲಿಸಿ."}
        },
        "ko": {
            "appName": {"message": "No Swipe - 스와이프 비활성화"},
            "appDesc": {"message": "터치 패드 두 손가락 네비게이션 비활성화."}
        },
        "lt": {
            "appName": {"message": "No Swipe - Išjungti Swipe"},
            "appDesc": {"message": "Išjungti navigaciją dviem pirštais ant touchpad."}
        },
        "lv": {
            "appName": {"message": "No Swipe - Deaktivēt Swipe"},
            "appDesc": {"message": "Deaktivēt divpirkstu navigāciju uz pieskāriena paneli."}
        },
        "ml": {
            "appName": {"message": "No Swipe - സ്വൈപ് അടയ്ക്കുക"},
            "appDesc": {"message": "ടച്ച്‌പാഡിലെ രണ്ട് അങ്കുളകളുടെ നാവിഗേഷൻ അടയ്ക്കുക."}
        },
        "mr": {
            "appName": {"message": "No Swipe - स्वाईप अक्षम करा"},
            "appDesc": {"message": "टचपॅडवर दोन बोटांचे नेव्हिगेशन अक्षम करा."}
        },
        "ms": {
            "appName": {"message": "No Swipe - Matikan Swipe"},
            "appDesc": {"message": "Matikan navigasi dua jari di touchpad."}
        },
        "nl": {
            "appName": {"message": "No Swipe - Swipe uitschakelen"},
            "appDesc": {"message": "Schakel de touchpad-navigatie met twee vingers uit."}
        },
        "no": {
            "appName": {"message": "No Swipe - Deaktiver sveiping"},
            "appDesc": {"message": "Deaktiver berøringsskjermens to-finger-navigasjon."}
        },
        "pl": {
            "appName": {"message": "No Swipe - Wyłącz Swipe"},
            "appDesc": {"message": "Wyłącz nawigację dwoma palcami na touchpadzie."}
        },
        "pt": {
            "appName": {"message": "No Swipe - Desativar Swipe"},
            "appDesc": {"message": "Desativar navegação de dois dedos no touchpad."}
        },
        "ro": {
            "appName": {"message": "No Swipe - Dezactivați Swipe"},
            "appDesc": {"message": "Dezactivați navigarea cu două degete pe touchpad."}
        },
        "ru": {
            "appName": {"message": "No Swipe - Отключить свайп"},
            "appDesc": {"message": "Отключите навигацию двумя пальцами на тачпаде."}
        },
        "sk": {
            "appName": {"message": "No Swipe - Zakázať Swipe"},
            "appDesc": {"message": "Zakázať navigáciu dvoma prstami na touchpad."}
        },
        "sl": {
            "appName": {"message": "No Swipe - Onemogoči povleci"},
            "appDesc": {"message": "Onemogoči navigacijo z dvema prstoma na sledilni ploščici."}
        },
        "sr": {
            "appName": {"message": "No Swipe - Onemogući povlačenje"},
            "appDesc": {"message": "Onemogućite navigaciju dva prsta na touchpadu."}
        },
        "sv": {
            "appName": {"message": "No Swipe - Inaktivera Swipe"},
            "appDesc": {"message": "Inaktivera tvåfingernavigering på styrplattan."}
        },
        "sw": {
            "appName": {"message": "No Swipe - Lemaza Swipe"},
            "appDesc": {"message": "Lemaza usafiri wa vidole viwili kwenye kifaa cha kugusa."}
        },
        "ta": {
            "appName": {"message": "No Swipe - ஸ்வைப் முடக்கு"},
            "appDesc": {"message": "டச்சிப்பேடில் இரண்டு விரல் நாவிகேசனை முடக்கு."}
        },
        "te": {
            "appName": {"message": "No Swipe - స్వైప్‌ను అమలు చేయి"},
            "appDesc": {"message": "టచ్‌ప్యాడ్‌లో రెండు వంటి నావిగేషన్‌ను నిల్వ చేయండి."}
        },
        "th": {
            "appName": {"message": "No Swipe - ปิดการใช้งาน Swipe"},
            "appDesc": {"message": "ปิดการนำทางด้วยนิ้วสองบนแผงสัมผัส"}
        },
        "tr": {
            "appName": {"message": "No Swipe - Swipe'yı Devre Dışı Bırak"},
            "appDesc": {"message": "Dokunmatik yüzeyde iki parmakla gezinmeyi devre dışı bırakın."}
        },
        "uk": {
            "appName": {"message": "No Swipe - Вимкніть Swipe"},
            "appDesc": {"message": "Вимкніть навігацію двома пальцями на тачпаді."}
        },
        "vi": {
            "appName": {"message": "No Swipe - Vô hiệu hóa Swipe"},
            "appDesc": {"message": "Vô hiệu hóa điều hướng hai ngón tay trên bàn di chuột."}
        },
        "zh_CN": {
            "appName": {"message": "No Swipe - 禁用滑动"},
            "appDesc": {"message": "禁用触摸板的双指导航。"}
        },
        "zh_TW": {
            "appName": {"message": "No Swipe - 停用滑動"},
            "appDesc": {"message": "停用觸控板的雙指導覽。"}
        }
    }
    const headers = {
        "ar": ["تم تحديث ملحقك No Swipe إلى أحدث إصدار."],
        "am": ["እባክህ የአካባቢ ትክክለኛ ያግኝ የሆነው ምሳሌ No Swipe ወደ ቅርብ እትም ተቀይሯል።"],
        "bg": ["Вашето разширение No Swipe беше актуализирано до последната версия."],
        "bn": ["আপনার এক্সটেনশন No Swipe সর্বশেষ সংস্করণে আপডেট করা হয়েছে।"],
        "ca": ["La teva extensió No Swipe s'ha actualitzat a l'última versió."],
        "cs": ["Vaše rozšíření No Swipe bylo aktualizováno na nejnovější verzi."],
        "da": ["Din udvidelse No Swipe blev opdateret til den nyeste version."],
        "de": ["Ihre Erweiterung No Swipe wurde auf die neueste Version aktualisiert."],
        "el": ["Η επέκτασή σας No Swipe ενημερώθηκε στην τελευταία έκδοση."],
        "en": ["Your extension No Swipe was updated to the latest version."],
        "es": ["Tu extensión No Swipe se actualizó a la última versión."],
        "et": ["Teie laiendus No Swipe on uuendatud uusimasse versiooni."],
        "fa": ["اکستنشن شما No Swipe به جدیدترین نسخه بروزرسانی شد."],
        "fi": ["Laajennuksesi No Swipe päivitettiin uusimpaan versioonin."],
        "fil": ["In-update ang iyong extension No Swipe sa pinakabagong bersyon."],
        "fr": ["Votre extension No Swipe a été mise à jour vers la dernière version."],
        "gu": ["તમારી વિસ્તરણ No Swipe નવીનતમ આવૃત્તિમાં અપડેટ થઈ છે."],
        "he": ["ההרחבה שלך No Swipe עודכנה לגרסה האחרונה."],
        "hi": ["आपका विस्तार No Swipe नवीनतम संस्करण में अपडेट किया गया था।"],
        "hr": ["Vaša ekstenzija No Swipe ažurirana je na najnoviju verziju."],
        "hu": ["A No Swipe kiegészítője a legújabb verzióra frissült."],
        "id": ["Ekstensi No Swipe Anda telah diperbarui ke versi terbaru."],
        "it": ["La tua estensione No Swipe è stata aggiornata all'ultima versione."],
        "ja": ["あなたの拡張機能No Swipeは最新バージョンに更新されました。"],
        "kn": ["ನಿಮ್ಮ ಎಕ್ಸ್ಟೆನ್ಷನ್ No Swipe ಇತ್ತೀಚಿನ ಆವೃತ್ತಿಗೆ ನವೀಕರಿಸಲಾಗಿದೆ."],
        "ko": ["귀하의 확장 프로그램 No Swipe가 최신 버전으로 업데이트되었습니다."],
        "lt": ["Jūsų plėtinys No Swipe buvo atnaujintas į naujausią versiją."],
        "lv": ["Jūsu paplašinājums No Swipe tika atjaunināts uz jaunāko versiju."],
        "ml": ["നിന്റെ എക്സ്റ്റൻഷൻ No Swipe പുതിയ പതിപ്പിലേക്ക് അപ്‌ഡേറ്റ് ചെയ്യപ്പെട്ടു."],
        "mr": ["तुमच्या विस्तार No Swipe चे नवीनतम आवृत्तीमध्ये अद्यतनित केले गेले आहे."],
        "ms": ["Sambungan anda No Swipe telah dikemas kini ke versi terkini."],
        "nl": ["Je extensie No Swipe is bijgewerkt naar de laatste versie."],
        "no": ["Utvidelsen din No Swipe ble oppdatert til den nyeste versjonen."],
        "pl": ["Twoje rozszerzenie No Swipe zostało zaktualizowane do najnowszej wersji."],
        "pt": ["Sua extensão No Swipe foi atualizada para a versão mais recente."],
        "ro": ["Extensia dvs. No Swipe a fost actualizată la ultima versiune."],
        "ru": ["Ваше расширение No Swipe было обновлено до последней версии."],
        "sk": ["Vaša rozšírenie No Swipe bolo aktualizované na najnovšiu verziu."],
        "sl": ["Vaša razširitev No Swipe je bila posodobljena na najnovejšo različico."],
        "sr": ["Vaša ekstenzija No Swipe ažurirana je na najnoviju verziju."],
        "sv": ["Din förlängning No Swipe har uppdaterats till den senaste versionen."],
        "sw": ["Uganiaji wako No Swipe umeboreshwa hadi toleo jipya."],
        "ta": ["உங்கள் விரிவாக்கம் No Swipe புதிய பதிப்புக்கு புதுப்பிக்கப்பட்டது."],
        "te": ["మీ ఎక్స్టెన్షన్ No Swipe తాజా సంచికలో అప్‌డేట్ చేయబడింది."],
        "th": ["ส่วนขยายของคุณ No Swipe ได้รับการอัปเดตเป็นเวอร์ชันล่าสุดแล้ว."],
        "tr": ["No Swipe uzantınız en son sürüme güncellendi."],
        "uk": ["Ваше розширення No Swipe було оновлено до останньої версії."],
        "vi": ["Tiện ích mở rộng No Swipe của bạn đã được cập nhật lên phiên bản mới nhất."],
        "zh_CN": ["您的扩展 No Swipe 已更新到最新版本。"],
        "zh_TW": ["您的擴充功能 No Swipe 已更新至最新版本。"]
    };
    
    
    const text = {
        "en": {
            "p1": "If you feel the No Swipe extension has value, you can support its maintenance by paying for the developer's coffee.",
            "p2": "Not in a position to donate? That's OK, you can still help:",
            "p3": "Assist in spreading the extension: Find a forum question regarding selectively disabling navigation and post a link to the extension. Or inform someone you know who has this issue about the extension.",
            "p4": "Report a bug or suggest an improvement",
            "p5": "We improve the image capture mechanism - we now have less misses.",
            "p6": ""
        },
        "ar": {
            "p1": "إذا شعرت أن إضافة No Swipe لها قيمة، يمكنك دعم صيانتها بدفع ثمن قهوة المطور.",
            "p2": "لا تستطيع التبرع؟ لا بأس، لا يزال بإمكانك المساعدة:",
            "p3": "ساعد في نشر الإضافة: ابحث عن سؤال في منتدى يتعلق بإيقاف التنقل بشكل انتقائي وانشر رابط الإضافة. أو أخبر شخصًا تعرفه لديه هذه المشكلة عن الإضافة.",
            "p4": "الإبلاغ عن خطأ أو اقتراح تحسين",
            "p5": "نحن نحسن آلية التقاط الصور - لدينا الآن عدد أقل من الأخطاء.",
            "p6": ""
        },
        "am": {
            "p1": "እንደምንም እንደ No Swipe እባብ ተጠቃሚ ቢሆን፣ አንደ ተምሳሌት የአማራኛ እባብ አብሮ እንደምን በማንበብ ይህን ምክንያት ታድም ይሁን፣",
            "p2": "አዎንን በማሳሰበት አንቀጥነም አባባት የማሳሰበት በሚናበዱበት አስቀድ በተግበሩ!",
            "p3": "ስለማንበብ እንደምንአርብ በተለያዩ አማራኛን ይግ የሚወጡበት",
            "p4": "ቀምታች.",
            "p5": "እንደእምነት አስተምስኩ።"
        },
        "bg": {
            "p1": "Ако смятате, че разширението No Swipe има стойност, можете да поддържате неговото поддържане, като платите за кафето на разработчика.",
            "p2": "Не сте в състояние да дарите? Няма проблем, все още можете да помогнете:",
            "p3": "Помогнете за разпространението на разширението: Намерете въпрос във форум относно селективно деактивиране на навигацията и публикувайте връзка към разширението. Или информирайте някой, когото познавате, който има този проблем за разширението.",
            "p4": "Докладвайте грешка или предложете подобрение",
            "p5": "Подобряваме механизма за заснемане на изображения - сега имаме по-малко пропуски.",
            "p6": ""
        },
        "bn": {
            "p1": "যদি আপনি অনুভব করেন যে No Swipe এক্সটেনশনের মূল্য রয়েছে, আপনি এটি রক্ষণাবেক্ষণের জন্য ডেভেলপারকে কফি খাওয়াতে সহায়তা করতে পারেন।",
            "p2": "দান করার অবস্থায় না? সমস্যা নেই, আপনি এখনও সাহায্য করতে পারেন:",
            "p3": "এক্সটেনশনটি ছড়িয়ে দিতে সহায়তা করুন: একটি ফোরামে গিয়ে নির্বাচনমূলকভাবে নেভিগেশন বন্ধ করার বিষয়ে প্রশ্ন খুঁজুন এবং এক্সটেনশনের লিঙ্ক পোস্ট করুন। অথবা আপনি যাকে জানেন তাকে এক্সটেনশনের বিষয়ে জানিয়ে দিন, যার এই সমস্যা রয়েছে।",
            "p4": "একটি বাগ রিপোর্ট করুন বা একটি উন্নতি প্রস্তাব দিন",
            "p5": "আমরা চিত্র ধারণ প্রক্রিয়া উন্নত করছি - এখন আমাদের কম মিস হচ্ছে।",
            "p6": ""
        },
        "ca": {
            "p1": "Si creus que l'extensió No Swipe té valor, pots donar suport al seu manteniment pagant el cafè del desenvolupador.",
            "p2": "No pots fer una donació? Està bé, encara pots ajudar:",
            "p3": "Ajuda a difondre l'extensió: Troba una pregunta al fòrum sobre com desactivar selectivament la navegació i publica un enllaç a l'extensió. O informa a algú que coneixes que tingui aquest problema sobre l'extensió.",
            "p4": "Reporta un error o suggereix una millora",
            "p5": "Millorem el mecanisme de captura d'imatges: ara tenim menys errors.",
            "p6": ""
        },
        "cs": {
            "p1": "Pokud se vám rozšíření No Swipe líbí, můžete podpořit jeho údržbu tím, že zaplatíte za kávu vývojáře.",
            "p2": "Nemůžete darovat? To je v pořádku, stále můžete pomoci:",
            "p3": "Pomozte šířit rozšíření: Najděte diskusní téma týkající se selektivního zakázání navigace a zveřejněte odkaz na rozšíření. Nebo informujte někoho, koho znáte a kdo má tento problém, o rozšíření.",
            "p4": "Nahlaste chybu nebo navrhněte vylepšení",
            "p5": "Zlepšujeme mechanismus snímání obrázků - nyní máme méně chyb.",
            "p6": ""
        },
        "da": {
            "p1": "Hvis du føler, at No Swipe-udvidelsen har værdi, kan du støtte dens vedligeholdelse ved at betale for udviklerens kaffe.",
            "p2": "Er du ikke i stand til at donere? Det er okay, du kan stadig hjælpe:",
            "p3": "Hjælp med at sprede udvidelsen: Find et forumspørgsmål om selektivt at deaktivere navigation og del et link til udvidelsen. Eller informér nogen, du kender, der har dette problem, om udvidelsen.",
            "p4": "Rapporter en fejl eller foreslå en forbedring",
            "p5": "Vi forbedrer billedfangstmekanismen - vi har nu færre fejl.",
            "p6": ""
        },
        "de": {
            "p1": "Wenn du der Meinung bist, dass die No Swipe-Erweiterung einen Wert hat, kannst du ihre Wartung unterstützen, indem du für den Kaffee des Entwicklers bezahlst.",
            "p2": "Bist du nicht in der Lage zu spenden? Kein Problem, du kannst trotzdem helfen:",
            "p3": "Hilf, die Erweiterung zu verbreiten: Finde eine Forumfrage zur selektiven Deaktivierung der Navigation und poste einen Link zur Erweiterung. Oder informiere jemanden, den du kennst, der dieses Problem hat, über die Erweiterung.",
            "p4": "Melde einen Fehler oder schlage eine Verbesserung vor",
            "p5": "Wir verbessern den Bildaufnahme-Mechanismus – jetzt haben wir weniger Fehler.",
            "p6": ""
        },
        "el": {
            "p1": "Αν θεωρείτε ότι η επέκταση No Swipe έχει αξία, μπορείτε να υποστηρίξετε τη συντήρησή της πληρώνοντας για τον καφέ του προγραμματιστή.",
            "p2": "Δεν είστε σε θέση να δωρίσετε; Κανένα πρόβλημα, μπορείτε να βοηθήσετε ακόμα:",
            "p3": "Βοηθήστε στη διάδοση της επέκτασης: Βρείτε μια ερώτηση σε φόρουμ σχετικά με την επιλεκτική απενεργοποίηση της πλοήγησης και δημοσιεύστε έναν σύνδεσμο στην επέκταση. Ή ενημερώστε κάποιον που γνωρίζετε και έχει αυτό το πρόβλημα για την επέκταση.",
            "p4": "Αναφέρετε ένα σφάλμα ή προτείνετε μια βελτίωση",
            "p5": "Βελτιώνουμε τον μηχανισμό καταγραφής εικόνας - τώρα έχουμε λιγότερες αποτυχίες.",
            "p6": ""
        },
        "es": {
            "p1": "Si consideras que la extensión No Swipe tiene valor, puedes apoyar su mantenimiento pagando por el café del desarrollador.",
            "p2": "¿No puedes donar? No pasa nada, aún puedes ayudar:",
            "p3": "Ayuda a difundir la extensión: Encuentra una pregunta en un foro sobre cómo desactivar selectivamente la navegación y publica un enlace a la extensión. O informa a alguien que conozcas que tenga este problema sobre la extensión.",
            "p4": "Informa de un error o sugiere una mejora",
            "p5": "Mejoramos el mecanismo de captura de imágenes: ahora tenemos menos fallos.",
            "p6": ""
        },
        "fi": {
            "p1": "Jos uskot, että No Swipe -laajennuksella on arvoa, voit tukea sen ylläpitoa maksamalla kehittäjän kahvin.",
            "p2": "Et ole tilanteessa lahjoittaa? Ei hätää, voit silti auttaa:",
            "p3": "Auta levittämään laajennusta: Etsi foorumilta kysymys, joka koskee navigoinnin valikoivaa poistamista käytöstä ja julkaise linkki laajennukseen. Tai kerro jollekin, jonka tiedät, joka on tämän ongelman kanssa, laajennuksesta.",
            "p4": "Ilmoita virheestä tai ehdota parannusta",
            "p5": "Parannamme kuvan sieppausmekanismia - meillä on nyt vähemmän virheitä.",
            "p6": ""
        },
        "fr": {
            "p1": "Si vous estimez que l'extension No Swipe a de la valeur, vous pouvez soutenir sa maintenance en payant le café du développeur.",
            "p2": "Vous n'êtes pas en mesure de faire un don ? Pas de problème, vous pouvez toujours aider :",
            "p3": "Aidez à diffuser l'extension : Trouvez une question sur un forum concernant la désactivation sélective de la navigation et publiez un lien vers l'extension. Ou informez quelqu'un que vous connaissez ayant ce problème de l'extension.",
            "p4": "Signalez un bug ou proposez une amélioration",
            "p5": "Nous améliorons le mécanisme de capture d'images - nous avons maintenant moins d'erreurs.",
            "p6": ""
        },
        "hi": {
            "p1": "यदि आपको लगता है कि No Swipe एक्सटेंशन का मूल्य है, तो आप इसके रखरखाव में डेवलपर के लिए कॉफी खरीदकर समर्थन कर सकते हैं।",
            "p2": "दान करने की स्थिति में नहीं? कोई बात नहीं, आप फिर भी मदद कर सकते हैं:",
            "p3": "एक्सटेंशन को फैलाने में मदद करें: एक फोरम में जाकर नेविगेशन को चयनात्मक रूप से अक्षम करने के बारे में एक प्रश्न खोजें और एक्सटेंशन का लिंक पोस्ट करें। या आप किसी ऐसे व्यक्ति को जो इस समस्या से जूझ रहा है, एक्सटेंशन के बारे में सूचित करें।",
            "p4": "बग रिपोर्ट करें या सुधार का सुझाव दें",
            "p5": "हम इमेज कैप्चर प्रणाली में सुधार कर रहे हैं - अब हमारे पास कम गलतियां हैं।",
            "p6": ""
        },
        "hr": {
            "p1": "Ako smatrate da No Swipe ekstenzija ima vrijednost, možete podržati njezino održavanje tako da platite za kavu programeru.",
            "p2": "Niste u mogućnosti donirati? Nema problema, i dalje možete pomoći:",
            "p3": "Pomozite u širenju ekstenzije: Pronađite pitanje na forumu koje se odnosi na selektivno onemogućavanje navigacije i objavite vezu na ekstenziju. Ili obavijestite nekog koga poznajete, a tko ima ovaj problem, o ekstenziji.",
            "p4": "Prijavite bug ili predložite poboljšanje",
            "p5": "Poboljšali smo mehanizam za hvatanje slika - sada imamo manje propuštenih.",
            "p6": ""
        },
        "he": {
            "p1": "אם אתה מרגיש שההרחבה No Swipe שווה, אתה יכול לתמוך בתחזוקה שלה על ידי תשלום עבור הקפה של המפתח.",
            "p2": "לא במצב לתרום? אין בעיה, עדיין תוכל לעזור:",
            "p3": "עזור בהפצת ההרחבה: מצא שאלה בפורום שמתייחסת להשבתה סלקטיבית של ניווט ופרסם קישור להרחבה. או יידע מישהו שאתה מכיר שיש לו את הבעיה הזו על ההרחבה.",
            "p4": "דווח על באג או הצע שיפור",
            "p5": "שיפרנו את מנגנון לכידת התמונה - עכשיו יש לנו פחות פספוסים.",
            "p6": ""
        },    
        "hu": {
            "p1": "Ha úgy érzi, hogy a No Swipe bővítménynek van értéke, támogathatja annak karbantartását azzal, hogy kifizeti a fejlesztő kávéját.",
            "p2": "Nem tud adományozni? Semmi gond, akkor is segíthet:",
            "p3": "Segítsen a bővítmény terjesztésében: Keressen egy fórum kérdést, amely a navigációs funkciók szelektív letiltásával foglalkozik, és tegye közzé a bővítmény linkjét. Vagy tájékoztasson egy olyan személyt, akit ismer, és akinek ez a problémája van, a bővítményről.",
            "p4": "Jelentse a hibát, vagy javasoljon fejlesztést",
            "p5": "Fejlesztettük a képfelvételi mechanizmust – most kevesebb hibát vétünk.",
            "p6": ""
        },
        "nl": {
            "p1": "Als je denkt dat de No Swipe-extensie waarde heeft, kun je de onderhoudskosten steunen door de koffie van de ontwikkelaar te betalen.",
            "p2": "Niet in staat om te doneren? Geen probleem, je kunt nog steeds helpen:",
            "p3": "Help bij het verspreiden van de extensie: Zoek een vraag op een forum over het selectief uitschakelen van navigatie en plaats een link naar de extensie. Of informeer iemand die je kent die dit probleem heeft over de extensie.",
            "p4": "Meld een bug of stel een verbetering voor",
            "p5": "We hebben het mechanisme voor het vastleggen van afbeeldingen verbeterd - we missen nu minder.",
            "p6": ""
        },
        "id": {
            "p1": "Jika Anda merasa ekstensi No Swipe memiliki nilai, Anda dapat mendukung pemeliharaannya dengan membayar kopi untuk pengembang.",
            "p2": "Tidak dalam posisi untuk berdonasi? Tidak masalah, Anda masih bisa membantu:",
            "p3": "Bantu sebarkan ekstensi: Temukan pertanyaan di forum mengenai penonaktifan navigasi secara selektif dan posting tautan ke ekstensi. Atau beri tahu seseorang yang Anda kenal yang memiliki masalah ini tentang ekstensi.",
            "p4": "Laporkan bug atau sarankan perbaikan",
            "p5": "Kami meningkatkan mekanisme pengambilan gambar - sekarang kami memiliki lebih sedikit kesalahan.",
            "p6": ""
        },
        "it": {
            "p1": "Se pensi che l'estensione No Swipe abbia valore, puoi supportarne la manutenzione pagando il caffè dello sviluppatore.",
            "p2": "Non sei in grado di fare una donazione? Va bene, puoi comunque aiutare:",
            "p3": "Aiuta a diffondere l'estensione: Trova una domanda su un forum riguardante la disabilitazione selettiva della navigazione e pubblica un link all'estensione. Oppure informa qualcuno che conosci e che ha questo problema sull'estensione.",
            "p4": "Segnala un bug o suggerisci un miglioramento",
            "p5": "Miglioriamo il meccanismo di acquisizione delle immagini - ora abbiamo meno errori.",
            "p6": ""
        },
        "ja": {
            "p1": "No Swipe拡張機能に価値があると感じた場合、開発者のコーヒー代を支払うことでその維持をサポートできます。",
            "p2": "寄付する立場ではありませんか？大丈夫です、まだ助ける方法があります:",
            "p3": "拡張機能の普及を手伝ってください：ナビゲーションの選択的無効化に関するフォーラムの質問を見つけて、拡張機能へのリンクを投稿してください。または、この問題を抱えている誰かに拡張機能について知らせてください。",
            "p4": "バグを報告するか、改善案を提案する",
            "p5": "画像キャプチャ機能を改善しました - 今ではミスが少なくなりました。",
            "p6": ""
        },
        "kn": {
            "p1": "ನೋ ಸ್ವೈಪ್ ವಿಸ್ತರಣೆಗೆ ಮೌಲ್ಯವಿದೆ ಎಂದು ನೀವು ಭಾವಿಸಿದರೆ, ಡೆವಲಪರ್‌ಗಾಗಿ ಕಾಫಿ ಪಾವತಿಸುವ ಮೂಲಕ ಅದರ ನಿರ್ವಹಣೆಗೆ ಸಹಾಯ ಮಾಡಬಹುದು.",
            "p2": "ದಾನ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲವೇ? ಯಾವುದೇ ಸಮಸ್ಯೆಯಿಲ್ಲ, ನೀವು ಇನ್ನೂ ಸಹಾಯ ಮಾಡಬಹುದು:",
            "p3": "ವಿಸ್ತರಣೆಯನ್ನು ಹಂಚಲು ಸಹಾಯ ಮಾಡಿ: ಆಯಾ ಸಂಚಲಿತವನ್ನು ನಿರ್ವಹಣೆಯನ್ನು ಆಯ್ದಂತೆ ನಿಷ್ಕ್ರಿಯಗೊಳಿಸುವುದರ ಕುರಿತು ಫೋರಂ ಪ್ರಶ್ನೆಯನ್ನು ಹುಡುಕಿ ಮತ್ತು ವಿಸ್ತರಣೆಯ ಲಿಂಕ್ ಅನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ. ಅಥವಾ ನೀವು ಪರಿಚಯಿಸುವ ವ್ಯಕ್ತಿಗೆ ಈ ಸಮಸ್ಯೆಯಿದೆ ಎಂದು ಹೇಳಿ.",
            "p4": "ಬಗ್ ವರದಿ ಮಾಡಿ ಅಥವಾ ಸುಧಾರಣೆಯನ್ನು ಶಿಫಾರಸು ಮಾಡಿ",
            "p5": "ನಾವು ಚಿತ್ರ ಹಿಡಿಯುವ ಯಂತ್ರವಿದ್ಯೆಯನ್ನು ಉತ್ತಮಗೊಳಿಸಿದ್ದೇವೆ - ಈಗ ನಮ್ಮ ಬಳಿ ಕಡಿಮೆ ತಪ್ಪುಗಳು ಇವೆ.",
            "p6": ""
        },
        "ko": {
            "p1": "No Swipe 확장 프로그램이 가치 있다고 생각하시면, 개발자의 커피를 사는 방식으로 유지보수를 지원할 수 있습니다.",
            "p2": "기부할 수 없나요? 괜찮아요, 여전히 도울 수 있는 방법이 있습니다:",
            "p3": "확장 프로그램을 퍼뜨리는 데 도움을 주세요: 내비게이션을 선택적으로 비활성화하는 방법에 대한 포럼 질문을 찾아 확장 프로그램의 링크를 게시하세요. 또는 이 문제를 겪고 있는 사람에게 확장 프로그램에 대해 알려주세요.",
            "p4": "버그를 신고하거나 개선 사항을 제안하세요",
            "p5": "이미지 캡처 메커니즘을 개선했습니다 - 이제 실수가 줄어들었습니다.",
            "p6": ""
        },
        "lv": {
            "p1": "Ja domājat, ka No Swipe paplašinājums ir vērtīgs, jūs varat atbalstīt tā uzturēšanu, samaksājot par izstrādātāja kafiju.",
            "p2": "Nav iespējas ziedot? Nav problēmu, jūs joprojām varat palīdzēt:",
            "p3": "Palīdziet izplatīt paplašinājumu: Atradiet forumā jautājumu, kas saistīts ar selektīvu navigācijas atslēgšanu, un publicējiet saiti uz paplašinājumu. Vai informējiet kādu, kuru pazīstat un kuram ir šī problēma, par paplašinājumu.",
            "p4": "Ziņojiet par kļūdu vai piedāvājiet uzlabojumu",
            "p5": "Mēs uzlabojām attēlu uzņemšanas mehānismu - tagad mēs pieļaujam mazāk kļūdu.",
            "p6": ""
        },
        "lt": {
        "p1": "Jei manote, kad No Swipe plėtinys yra vertingas, galite paremti jo priežiūrą, sumokėdami už kūrėjo kavą.",
        "p2": "Negalite paaukoti? Nesijaudinkite, vis tiek galite padėti:",
        "p3": "Padėkite platinti plėtinį: Suraskite forumo klausimą apie selektyvų navigacijos išjungimą ir paskelbkite nuorodą į plėtinį. Arba informuokite ką nors, ką žinote, ir kuriam kyla ši problema, apie plėtinį.",
        "p4": "Praneškite apie klaidą arba pasiūlykite patobulinimus",
        "p5": "Mes patobulinome vaizdų fiksavimo mechanizmą – dabar mes darome mažiau klaidų.",
        "p6": ""
    },
        "pl": {
            "p1": "Jeśli uważasz, że rozszerzenie No Swipe ma wartość, możesz wesprzeć jego utrzymanie, płacąc za kawę dla dewelopera.",
            "p2": "Nie możesz przekazać darowizny? To w porządku, nadal możesz pomóc:",
            "p3": "Pomóż w rozpowszechnianiu rozszerzenia: Znajdź pytanie na forum dotyczące selektywnego wyłączania nawigacji i opublikuj link do rozszerzenia. Albo poinformuj kogoś, kogo znasz, a kto ma ten problem, o rozszerzeniu.",
            "p4": "Zgłoś błąd lub zaproponuj poprawkę",
            "p5": "Poprawiamy mechanizm przechwytywania obrazów - teraz mamy mniej pomyłek.",
            "p6": ""
        },
        "pt": {
            "p1": "Se você acha que a extensão No Swipe tem valor, pode apoiar sua manutenção pagando o café do desenvolvedor.",
            "p2": "Não pode fazer uma doação? Tudo bem, você ainda pode ajudar:",
            "p3": "Ajude a divulgar a extensão: Encontre uma pergunta em um fórum sobre como desativar a navegação seletivamente e publique um link para a extensão. Ou informe alguém que você conhece que tenha esse problema sobre a extensão.",
            "p4": "Reporte um bug ou sugira uma melhoria",
            "p5": "Estamos melhorando o mecanismo de captura de imagens - agora temos menos falhas.",
            "p6": ""
        },
        "ro": {
            "p1": "Dacă simți că extensia No Swipe are valoare, poți sprijini mentenanța acesteia plătind cafeaua dezvoltatorului.",
            "p2": "Nu ești într-o poziție să donezi? Nu-i nimic, poți totuși ajuta:",
            "p3": "Ajută la răspândirea extensiei: Găsește o întrebare pe un forum despre dezactivarea selectivă a navigării și postează un link către extensie. Sau informează pe cineva pe care îl cunoști și care are această problemă despre extensie.",
            "p4": "Raportează un bug sau sugerează o îmbunătățire",
            "p5": "Îmbunătățim mecanismul de captură a imaginii - acum avem mai puține erori.",
            "p6": ""
        },
        "ru": {
            "p1": "Если вы считаете, что расширение No Swipe полезно, вы можете поддержать его обслуживание, заплатив за кофе разработчика.",
            "p2": "Не в состоянии сделать пожертвование? Ничего страшного, вы все равно можете помочь:",
            "p3": "Помогите распространить расширение: Найдите вопрос на форуме о селективном отключении навигации и разместите ссылку на расширение. Или сообщите кому-то, кого вы знаете, кто сталкивается с этой проблемой, о расширении.",
            "p4": "Сообщите об ошибке или предложите улучшение",
            "p5": "Мы улучшаем механизм захвата изображений - теперь у нас меньше ошибок.",
            "p6": ""
        },
        "sr": {
            "p1": "Ako smatraš da No Swipe proširenje ima vrednost, možeš podržati njegovo održavanje tako što ćeš platiti kafu za developera.",
            "p2": "Nisi u mogućnosti da doniraš? Nema problema, i dalje možeš pomoći:",
            "p3": "Pomozite u širenju proširenja: Pronađite forum pitanje o selektivnom onemogućavanju navigacije i postavite link ka proširenju. Ili obavestite nekog koga poznajete, a ko ima ovaj problem, o proširenju.",
            "p4": "Prijavite grešku ili predložite poboljšanje",
            "p5": "Poboljšavamo mehanizam za snimanje slika - sada imamo manje grešaka.",
            "p6": ""
        },
        "sv": {
            "p1": "Om du tycker att No Swipe-tillägget har värde, kan du stödja dess underhåll genom att betala för utvecklarens kaffe.",
            "p2": "Kan inte donera? Det är okej, du kan fortfarande hjälpa till:",
            "p3": "Hjälp till att sprida tillägget: Hitta en forumfråga om selektivt inaktivera navigering och posta en länk till tillägget. Eller informera någon du känner som har detta problem om tillägget.",
            "p4": "Rapportera en bugg eller föreslå en förbättring",
            "p5": "Vi förbättrar bildfångstmekanismen - vi har nu färre missar.",
            "p6": ""
        },
        "tr": {
            "p1": "Eğer No Swipe uzantısının bir değeri olduğunu düşünüyorsanız, geliştiricinin kahvesini ödeyerek bakımına destek olabilirsiniz.",
            "p2": "Bağış yapma durumunda değil misiniz? Sorun değil, yine de yardımcı olabilirsiniz:",
            "p3": "Uzantıyı yaymakta yardımcı olun: Navigasyonun seçici olarak devre dışı bırakılmasıyla ilgili bir forum sorusu bulun ve uzantıya bir bağlantı paylaşın. Veya bu sorunu yaşayan tanıdığınız birine uzantıyı bildirin.",
            "p4": "Bir hata rapor edin veya bir iyileştirme önerin",
            "p5": "Görüntü yakalama mekanizmasını geliştiriyoruz - artık daha az hata yapıyoruz.",
            "p6": ""
        },
        "uk": {
            "p1": "Якщо ви вважаєте, що розширення No Swipe має цінність, ви можете підтримати його обслуговування, заплативши за каву розробнику.",
            "p2": "Не маєте можливості пожертвувати? Це не проблема, ви все одно можете допомогти:",
            "p3": "Допоможіть поширити розширення: Знайдіть запитання на форумі щодо вибіркового вимкнення навігації і опублікуйте посилання на розширення. Або повідомте когось, кого ви знаєте і хто має цю проблему, про розширення.",
            "p4": "Повідомте про помилку або запропонуйте поліпшення",
            "p5": "Ми покращуємо механізм захоплення зображень - тепер у нас менше помилок.",
            "p6": ""
        },
        "zh_CN": {
            "p1": "如果您认为No Swipe扩展程序有价值，您可以通过支付开发者的咖啡费来支持其维护。",
            "p2": "无法捐款？没关系，您仍然可以提供帮助：",
            "p3": "帮助传播扩展程序：在论坛上找到关于如何选择性禁用导航的问题，并发布扩展程序的链接。或者告诉您认识的人，他们有这个问题，关于扩展程序的事情。",
            "p4": "报告错误或提出改进建议",
            "p5": "我们改进了图像捕捉机制 - 现在我们有更少的错误。",
            "p6": ""
        },
        "zh_TW": {
            "p1": "如果您認為No Swipe擴展程式有價值，您可以透過支付開發者的咖啡費來支持其維護。",
            "p2": "無法捐款？沒關係，您仍然可以提供幫助：",
            "p3": "幫助傳播擴展程式：在論壇上找到有關如何選擇性禁用導航的問題，並發布擴展程式的連結。或者告訴您認識的人，他們有這個問題，關於擴展程式的事情。",
            "p4": "報告錯誤或提出改進建議",
            "p5": "我們改進了圖像捕捉機制 - 現在我們有更少的錯誤。",
            "p6": ""
        },
        "et": {
        "p1": "Kui arvate, et No Swipe laiendus on väärtuslik, saate selle hooldust toetada, makstes arendaja kohvi eest.",
        "p2": "Ei ole võimalik annetada? Pole probleemi, saate ikka aidata:",
        "p3": "Aidake laiendust levitada: Leidke foorumist küsimus, mis käsitleb navigeerimise valikulist keelamist, ja postitage laienduse link. Või informeerige kedagi, keda teate, kellel on see probleem, laienduse kohta.",
        "p4": "Teata veast või tee ettepanek parendamiseks",
        "p5": "Parandasime piltide salvestamise mehhanismi – nüüd on meil vähem möödalaske.",
        "p6": ""
    },
    "fa": {
        "p1": "اگر احساس می‌کنید که افزونه No Swipe ارزش دارد، می‌توانید با پرداخت هزینه قهوه برای توسعه‌دهنده از نگهداری آن حمایت کنید.",
        "p2": "نمی‌توانید کمک مالی کنید؟ اشکالی ندارد، همچنان می‌توانید کمک کنید:",
        "p3": "در گسترش افزونه کمک کنید: یک سوال در انجمن پیدا کنید که به غیرفعال‌سازی انتخابی ناوبری اشاره دارد و لینک افزونه را منتشر کنید. یا به کسی که می‌شناسید و این مشکل را دارد، در مورد افزونه اطلاع دهید.",
        "p4": "گزارش یک اشکال یا پیشنهاد بهبود",
        "p5": "ما مکانیسم ضبط تصویر را بهبود بخشیدیم - حالا اشتباهات کمتری داریم.",
        "p6": ""
    },
    "fil": {
        "p1": "Kung sa tingin mo ay may halaga ang No Swipe na extension, maaari mong suportahan ang pagpapanatili nito sa pamamagitan ng pagbabayad para sa kape ng developer.",
        "p2": "Hindi makapag-donate? Walang problema, maaari ka pa ring tumulong:",
        "p3": "Tumulong sa pagpapalaganap ng extension: Maghanap ng isang tanong sa forum tungkol sa selectively disabling navigation at mag-post ng link sa extension. O ipaalam sa isang tao na kilala mo na may problema ito tungkol sa extension.",
        "p4": "Mag-report ng bug o magmungkahi ng pagpapabuti",
        "p5": "Pinabuti namin ang mekanismo ng pagkuha ng imahe - ngayon ay mas kaunti na ang aming mga pagkakamali.",
        "p6": ""
    },    "gu": {
        "p1": "જો તમે માનતા હો કે No Swipe એક્શનનું મૂલ્ય છે, તો તમે ડેવલપરની કૉફી માટે ચૂકવણી કરીને તેનો જતન કરવા માટે સહાય કરી શકો છો.",
        "p2": "ડોનેટ કરવા માટે સ્થિતિમાં નથી? કોઈ સમસ્યા નથી, તમે હજુ પણ મદદ કરી શકો છો:",
        "p3": "એક્શનને ફેલાવવામાં મદદ કરો: ફોરમ પર એવી કોઇ સવાલ શોધો જે પસંદગીથી નેવિગેશન અક્ષમ કરવાનો છે અને એક્શનનો લિંક પોસ્ટ કરો. અથવા કોઈને જણાવો જેને તમે જાણો છો અને જેના પાસે આ સમસ્યા છે, એક્શન વિશે.",
        "p4": "બગ રિપોર્ટ કરો અથવા સુધારણા સૂચવો",
        "p5": "આમે ઇમેજ કૅપ્ચર મિકેનિઝમને સુધાર્યું છે - હવે અમે ઓછા મિસ કરીએ છીએ.",
        "p6": ""
    },
    "ms": {
        "p1": "Sekiranya anda merasakan bahawa sambungan No Swipe mempunyai nilai, anda boleh menyokong penyelenggaraannya dengan membayar untuk kopi pembangun.",
        "p2": "Tidak dalam kedudukan untuk menderma? Tiada masalah, anda masih boleh membantu:",
        "p3": "Bantu sebarkan sambungan: Cari soalan forum mengenai penonaktifan navigasi secara selektif dan kongsi pautan ke sambungan tersebut. Atau maklumkan kepada seseorang yang anda kenali yang mempunyai masalah ini tentang sambungan tersebut.",
        "p4": "Laporkan pepijat atau cadangkan penambahbaikan",
        "p5": "Kami meningkatkan mekanisme pengambilan gambar - sekarang kami mempunyai lebih sedikit kesilapan.",
        "p6": ""
    },
    "ml": {
        "p1": "നോ സ്വൈപ്പ് എക്സ്റ്റൻഷനിൽ മൂല്യം ഉണ്ടെങ്കിൽ, ഡെവലപ്പറുടെ കാപ്പി പണം നൽകി അതിന്റെ പരിപാലനം പിന്തുണയ്ക്കാം.",
        "p2": "ദാനം ചെയ്യാൻ സ്ഥിതിയിലല്ലേ? പ്രശ്നമില്ല, നിങ്ങൾ എങ്കിലും സഹായിക്കാം:",
        "p3": "എക്സ്റ്റൻഷൻ പ്രചാരണം ചെയ്യാൻ സഹായിക്കുക: ഫോറത്തിൽ സെലക്റ്റീവ് നാവിഗേഷൻ അപ്രാപ്തമാക്കൽ സംബന്ധിച്ചുള്ള ഒരു ചോദ്യത്തിന് ഇഷ്ടാനുസൃതമായി എക്സ്റ്റൻഷന്റെ ലിങ്ക് പോസ്റ്റ് ചെയ്യുക. അല്ലെങ്കിൽ ഈ പ്രശ്നം ഉള്ള ഒരാളെ അറിയിച്ച് എക്സ്റ്റൻഷൻ കുറിച്ച് അവരെ അറിയിക്കൂ.",
        "p4": "ബഗ് റിപ്പോർട്ട് ചെയ്യുക അല്ലെങ്കിൽ മെച്ചപ്പെടുത്തലുകൾ നിർദ്ദേശിക്കുക",
        "p5": "ഞങ്ങൾ ചിത്രപ്പകർപ്പിന്റെ യാന്ത്രികത മെച്ചപ്പെടുത്തിയത് - ഇപ്പോൾ ഞങ്ങൾക്ക് കുറച്ച് തെറ്റുകൾ ആണ്.",
        "p6": ""
    },
    "mr": {
        "p1": "जर आपल्याला वाटत असेल की No Swipe एक्सटेंशनला मूल्य आहे, तर आपण डेव्हलपरच्या कॉफी साठी पैसे देऊन त्याच्या देखभालीला पाठिंबा देऊ शकता.",
        "p2": "दान करण्याची स्थिती नाही का? काळजी करू नका, तुम्ही अजूनही मदत करू शकता:",
        "p3": "एक्सटेंशनच्या प्रसारात मदत करा: फोरमवर निवडकपणे नॅव्हिगेशन अक्षम करणे यावर एक प्रश्न शोधा आणि त्या एक्सटेंशनचा लिंक पोस्ट करा. किंवा त्यांना सांगितलेल्याला जो याबद्दल समस्या असलेल्या व्यक्तीस एक्सटेंशनबद्दल सांगितले.",
        "p4": "बग रिपोर्ट करा किंवा सुधारणा सुचवा",
        "p5": "आम्ही चित्र कॅप्चर यंत्रणा सुधारित केली आहे - आता आम्हाला कमी चुका होतात.",
        "p6": ""
    },
    "no": {
        "p1": "Hvis du føler at No Swipe-utvidelsen har verdi, kan du støtte vedlikeholdet ved å betale for utviklerens kaffe.",
        "p2": "Ikke i en posisjon til å donere? Det er greit, du kan fortsatt hjelpe:",
        "p3": "Hjelp til med å spre utvidelsen: Finn et forumspørsmål om selektiv deaktivering av navigasjon og legg ut en lenke til utvidelsen. Eller informer noen du kjenner som har dette problemet om utvidelsen.",
        "p4": "Rapporter en feil eller foreslå en forbedring",
        "p5": "Vi har forbedret bildeoppsamlingsmekanismen - nå har vi færre feil.",
        "p6": ""
    },   
     "sl": {
        "p1": "Če menite, da ima razširitev No Swipe vrednost, lahko podprete njeno vzdrževanje s plačilom za razvijalčevo kavo.",
        "p2": "Niste v položaju, da bi darovali? Ni problema, še vedno lahko pomagate:",
        "p3": "Pomagajte pri širjenju razširitve: Poiščite vprašanje na forumu, ki se nanaša na selektivno onemogočanje navigacije, in objavite povezavo do razširitve. Ali pa obvestite nekoga, ki ga poznate in ima ta problem, o razširitvi.",
        "p4": "Poročajte o napaki ali predlagajte izboljšave",
        "p5": "Izboljšali smo mehanizem zajema slik – zdaj imamo manj napak.",
        "p6": ""
    },
    "sk": {
        "p1": "Ak máte pocit, že rozšírenie No Swipe má hodnotu, môžete podporiť jeho údržbu tým, že zaplatíte za kávu vývojárovi.",
        "p2": "Nie ste v pozícii na darovanie? To nevadí, stále môžete pomôcť:",
        "p3": "Pomôžte šíriť rozšírenie: Nájdite na fóre otázku týkajúcu sa selektívneho vypnutia navigácie a zverejnite odkaz na rozšírenie. Alebo informujte niekoho, koho poznáte a kto má tento problém, o rozšírení.",
        "p4": "Nahláste chybu alebo navrhnite vylepšenie",
        "p5": "Vylepšili sme mechanizmus zachytávania obrázkov – teraz máme menej chýb.",
        "p6": ""
    },
    "sw": {
        "p1": "Iki unavyohisi kuwa na thamani ya nyongeza ya No Swipe, unaweza kusaidia matengenezo yake kwa kulipa kwa kahawa ya mtaalamu.",
        "p2": "Hauko katika nafasi ya kutoa mchango? Hilo ni sawa, bado unaweza kusaidia:",
        "p3": "Tusaidia kusambaza nyongeza: Tafuta swali kwenye jukwaa kuhusu kuzima kwa hiari kwa urambazaji na weka kiungo cha nyongeza. Au kuwaarifu watu unawajua wanaoshughulika na tatizo hili kuhusu nyongeza hiyo.",
        "p4": "Ripoti hitilafu au pendekeza maboresho",
        "p5": "Tumeimarisha utaratibu wa kuchukua picha - sasa tuna makosa machache.",
        "p6": ""
    },
    "te": {
        "p1": "మీకు No Swipe ఎక్స్‌టెన్షన్ విలువ ఉందని భావిస్తే, మీరు డెవలపర్ యొక్క కాఫీ కోసం చెల్లించి దాని నిర్వహణను మద్దతు ఇవ్వవచ్చు.",
        "p2": "దానం చేయడానికి స్థితిలో లేరా? ఏమీ లేదు, మీరు ఇంకా సహాయం చేయగలరు:",
        "p3": "ఎక్స్‌టెన్షన్‌ను వ్యాప్తి చేయడంలో సహాయం చేయండి: సెలెక్టివ్‌గా నావిగేషన్‌ను నిలిపివేయడంపై ఫోరంలో ఒక ప్రశ్న కనుగొనండి మరియు ఎక్స్‌టెన్షన్‌కు లింక్‌ని పోస్ట్ చేయండి. లేదా ఈ సమస్యను ఎదుర్కొంటున్న ఎవరో మీకు తెలుసు అయితే, వారిని ఎక్స్‌టెన్షన్ గురించి తెలియజేయండి.",
        "p4": "బగ్ నివేదించండి లేదా మెరుగుదలని సూచించండి",
        "p5": "మేము చిత్ర క్యాప్చర్ యంత్రవిజ్ఞానాన్ని మెరుగుపరిచాము - ఇప్పుడు మాకు తక్కువ తప్పులు ఉన్నాయి.",
        "p6": ""
    },
    "th": {
        "p1": "หากคุณรู้สึกว่าโปรแกรมเสริม No Swipe มีค่า คุณสามารถสนับสนุนการบำรุงรักษาของมันโดยการจ่ายเงินสำหรับกาแฟของนักพัฒนา",
        "p2": "ไม่สามารถบริจาคได้ใช่ไหม? ไม่เป็นไร คุณยังสามารถช่วยได้:",
        "p3": "ช่วยเผยแพร่โปรแกรมเสริม: ค้นหาคำถามในฟอรั่มเกี่ยวกับการปิดการใช้งานการนำทางแบบเลือกและโพสต์ลิงก์ไปยังโปรแกรมเสริม หรือแจ้งให้ใครสักคนที่คุณรู้จักที่มีปัญหานี้เกี่ยวกับโปรแกรมเสริม",
        "p4": "รายงานข้อบกพร่องหรือแนะนำการปรับปรุง",
        "p5": "เราได้ปรับปรุงกลไกการจับภาพภาพ - ตอนนี้เรามีข้อผิดพลาดน้อยลง",
        "p6": ""
    },
    "vi": {
        "p1": "Nếu bạn cảm thấy tiện ích No Swipe có giá trị, bạn có thể hỗ trợ việc duy trì nó bằng cách trả tiền cho cà phê của nhà phát triển.",
        "p2": "Không có khả năng quyên góp? Không sao, bạn vẫn có thể giúp đỡ:",
        "p3": "Giúp lan truyền tiện ích: Tìm một câu hỏi trên diễn đàn về việc vô hiệu hóa điều hướng có chọn lọc và đăng liên kết đến tiện ích. Hoặc thông báo cho ai đó mà bạn biết có vấn đề này về tiện ích.",
        "p4": "Báo cáo lỗi hoặc đề xuất cải tiến",
        "p5": "Chúng tôi đã cải thiện cơ chế chụp ảnh - giờ chúng tôi có ít lỗi hơn.",
        "p6": ""
    },
    "ta": {
        "p1": "If you feel the No Swipe extension has value, you can support its maintenance by paying for the developer's coffee.",
        "p2": "Not in a position to donate? That's OK, you can still help:",
        "p3": "Assist in spreading the extension: Find a forum question regarding selectively disabling navigation and post a link to the extension. Or inform someone you know who has this issue about the extension.",
        "p4": "Report a bug or suggest an improvement",
        "p5": "We improve the image capture mechanism - we now have less misses.",
        "p6": ""
    }
    }
            
    const { browser, lang } = useParams();
    let url ="https://chromewebstore.google.com/detail/image-downloader-save-all/fpelahbljekknahkcaegamhcndkfpfnc";
    if(browser=="edge"){
        url ="https://microsoftedge.microsoft.com/addons/detail/image-downloader-save-a/flbolonkboilcbnohjlchlfgdalomdpl"
    }else if(browser=="opera"){
        url="opera"
    }
    let header = headers[lang];
    let appName =appNameTrans[lang]["appName"]["message"];
    let p1 = text[lang]['p1'];
    let p2 = text[lang]['p2'];
    let p3 = text[lang]['p3'];
    let p4 = text[lang]['p4'];
    let p5 = text[lang]['p5'];
    let p6 = text[lang]['p6'];
    let enLink = "../no_swipe-rate_us/"+browser+"/en";
        return(
            <>
        <div  style={{backgroundImage:"URL(/../assets/meercat-download-images.jpeg)", width:"1024px", height:"1024px"}}>
            {lang=='en'?"":<NavLink to= {enLink}><img src="/../assets/en.svg" style={{float:"right", width:"44px", margin:"24px 12px"}} /></NavLink>}
            <div style={{color:"white",margin:"24px,8px,24px,8px"}}>
            <div>
                <img src="/assets/NoSwipe16T.png" height={"16px"} ></img> <h2 style={{paddingTop:"0px",color:"white",display:"inline-block"}}>{appName}</h2>
</div>
<h2 style={{backgroundColor:"rgb(0,0,0,0.6)",color:"white",padding:'2px,8px, 2px'}}>

    <div style={{paddingLeft:'8px', paddingTop:'0px',paddingBottom:'2px'}}><p style={{marginTop:'0'}}>

<div>
<h2 style={{paddingTop:"24px",color:"white"}}>{header}</h2>

</div>
    {p1}&nbsp;<a href="https://www.paypal.com/donate/?business=XSHJU8NSLL73L&no_recurring=1&item_name=browser+extensions+developer&currency_code=USD"><img src="/assets/paypal-icon.png"/></a></p>
        {p2}<br/>
        <ol>
            <li>
                {p3}
                </li>
                <li>
                <NavLink to="/en/contact">{p4}</NavLink>.
                </li>
            </ol>
        </div>
</h2>
</div>
        </div>
        </>
        )
}


